import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

import colors from '../../styles/colors';

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-items: start;
`;
const selected = css`
  color: ${colors.white};
  text-decoration: underline;
  text-decoration-line: overline;
`;
const linkCss = css`
  color: ${colors.blue};
  transition: all 0.2s ease;
  font-variant: small-caps;
  line-height: unset;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 1.3px;
  padding: 1rem 15px 0 0;
`;

export default () => {
  const [currentRoute, updateRoute] = useState();
  useEffect(() => {
    updateRoute(window.location.pathname.replace(/\//g, ''));
  }, []);
  return (
    <Nav>
      <Link css={[linkCss, currentRoute === '' && selected]} to='/'>
        about
      </Link>
      <Link css={[linkCss, currentRoute === 'work' && selected]} to='/work'>
        work
      </Link>
      <Link css={[linkCss, currentRoute === 'media' && selected]} to='/media'>
        media
      </Link>
      <Link
        css={[linkCss, currentRoute === 'contact' && selected]}
        to='/contact'
      >
        contact
      </Link>
    </Nav>
  );
};
