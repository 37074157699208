import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import Header from './Header';
import '../../styles/reset.css';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
  padding-top: 2rem;
  @media (max-width: 950px) {
    padding-top: 0.5rem;
    width: 90vw;
  }
`;

const Layout = ({ children, title }) => (
  <>
    <Helmet>
      <meta charSet='utf-8' />
      <title>Grant Glidewell</title>
      <meta name='description' content="grant glidewell's portfolio" />
    </Helmet>
    <Header>{title}</Header>
    <Main>{children}</Main>
    <footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Layout.defaultProps = {
  title: 'Grant Glidewell',
};

export default Layout;
