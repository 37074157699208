import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from '../../styles/colors';

import Navigation from './Navigation';

const Wrapper = styled.header`
  min-height: 150px;
  margin: 0;
  padding: 2rem 5rem;
  background-color: ${colors.darkGray};
  @media (max-width: 950px) {
    padding: 2rem 1rem;
  }
  h1 {
    padding-top: 3rem;
    color: ${colors.white};
    font-size: 30px;
  }
`;

const Header = ({ children }) => {
  return (
    <Wrapper>
      <Navigation />
      <h1>{children}</h1>
    </Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Header;
